<style lang="scss" scoped>
	@import "./seller-participation.partial";
    .return {
        background: $primary-color;
        color: white;
        right: 3rem;
        top: 0rem;
        z-index: 1;
        border-radius: 35px;
        height: 60px;
        width: 60px;
        border: none;
    }
    .overflow-container{
        //max-height: 70vh;
        //overflow-x: auto;
        padding: 20px !important;
    }
    .page-table-line-special{
        background: #F7F7F7;
    }
</style>
<template>
    <div class="container-fluid" style="min-height:60vw">
        <cc-loader v-show="loading" />
        <div class="row overflow-container" id="modalBodyPrint" v-show="!loading">
            <div class="col" v-if="!current_request">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="pl-3 text-center">ID</th>
                            <th>CLIENTE</th>
                            <th class="text-left">PRODUTOS</th>
                            <th style="width:25px">CONCORRENTES</th>
                            <th class="text-center">STATUS</th>
                            <th>Criada em</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(request, index) in requests" :class="getSpecialBackground(index)" v-bind:key="request.id">
                            <td class="text-center"><strong>#{{ request.id || '-'}}</strong></td>
                            <td class="text-uppercase">{{ request.name || '-'}}</td>
                            <td class="text-left">
                                <span class="prod-count" @click="current_request = request">
                                    {{ request.products_count }}
                                </span>
                            </td>
                            <td class="text-left">{{ request.sellers_count }}</td>
                            <td class="text-center">
                                <cc-status :status="request.request_seller_status"></cc-status>
                            </td>
                            <td>{{ request.created_at | formatDate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col" v-if="current_request">
                <SellerOffers
                    :isSellersManagement="isSellersManagement"
                    :request_id="current_request.id"
                    :seller_id="request_seller.seller.id" />
            </div>
        </div>
    </div>
</template>
<script>
import RequestService from "@/services/v3/request.service";
import SellerService from "@/services/v3/providers/seller.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerOffers from "@/modals/providers/sellers/_offers.partial"

export default {
    mixins: [ loaderMixin ],
    props: [ "request_seller" ],
    components: { SellerOffers },
    data() {
        return {
            loading: false,
            isSellersManagement: true,
            service: new RequestService(),
            seller_svc: new SellerService(),
            requests: [],
            current_seller: null,
            current_request: null,
            filters: {},
            statuses: [
                { id: 'ALL', name: 'Todos' },
                { id: 'NOT_TYPED', name: 'Não Digitados' },
                { id: 'TYPED', name: 'Digitados' },
                { id: 'ORDERED', name: 'Pedido Gerado' },
                { id: 'NOT_ORDERED', name: 'Sem Pedido' },
                { id: 'NOT_OFFERED', name: 'Não oferta' },
            ]
        }
    },
    watch: {
        'filters.status' : function() {
            this.providers.forEach(p => p.visible = (
                this.filters.status == null ||
                this.filters.status.id == "ALL" ||
                this.filters.status.id == p.status))
        }
    },
    methods: {
        load_requests() {
            this.loading = true;
            let params = { seller_id: this.request_seller.seller.id, with_pagination: 0 }
            return this.service.list(params).then((response) => {
                this.loading = false;
                this.requests = response.data
            }).catch(err => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        getSpecialBackground(index) {
            if (index % 2 != 0) return 'page-table-line-special'
        }
    },
    created() {
        this.load_requests()
    }
};
</script>
