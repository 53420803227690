<template>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col col-12 p-3 page-title">
                        <h3>
                            <i class="fas fa-map-marked-alt"></i>
                            Mapa fornecedores x clientes
                        </h3>
                    </div>
                    <div class="col-12">
                        <form action="" @submit.prevent="list()">
                            <div class="row py-3">
                                <div class="col col-3 filters">
                                    <label for="" class="label-control">Cliente</label>
                                    <cc-select-v2
                                        tracker="id"
                                        :text="'name'"
                                        :searchable="true"
                                        :placeholder="'Selecione um cliente'"
                                        :options="clients"
                                        v-model="filters.client" />
                                </div>
                                <div class="col col-2 filter-controls">
                                    <button type="submit" style="display:none;" />
                                    <cc-button
                                        :nolink="true"
                                        type="button"
                                        :classes="'fixed danger-outline'"
                                        :icon="'far fa-trash-alt'"
                                        :content="'Limpar Filtros'"
                                        @click="clear_filters" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <no-data-available v-if="providers.length == 0 && !is_loading" :text="'Nenhum fornecedor encontrado'" />
                        <div class="container-fluid provider" v-for="provider in providers" v-bind:key="'head-office-' + provider.head_office.id">
                            <div class="row header">
                                <div class="col col-3">
                                    <b>#{{ provider.id }}</b>
                                    <i class="fas fa-user-tie seller-icon"
                                        :title="provider.contact ? provider.contact.toUpperCase() : ''"
                                        :style="{ visibility: !provider.head_office.is_head_office ? 'hidden' : 'visible' }"></i>
                                    {{ provider.name }}
                                </div>
                                <div class="col text-nowrap">{{ provider.cnpj || "" | VMask('## ### ###/####-##') }}</div>
                                <div class="col text-uppercase col-3">{{ provider.trading_name }}</div>
                                <div class="col text-uppercase">{{ provider.head_office.state && provider.head_office.state.name }}</div>
                                <div class="col text-uppercase">{{ provider.head_office.city && provider.head_office.city.name }}</div>
                            </div>
                            <div class="row sellers" v-for="seller in provider.sellers" v-bind:key="seller.id">
                                <template v-for="client in seller.clients">
                                    <div class="col col-12" v-bind:key="client.id" v-if="clients.some(c => c.id == client.id && (client.visible == undefined || client.visible))">
                                        <div class="col col-4 seller-client">
                                            {{ client.name }}
                                        </div>
                                        <div class="col col-3 name">
                                            <i class="fas fa-user-tag"
                                                @click="manage_sellers(client, provider)"
                                                :title="'Vendedor #' + seller.id "></i>
                                            {{ seller.name }}
                                        </div>
                                        <div class="col">
                                            <cc-percentage v-if="client.pivot" :value="client.pivot.participation" />
                                        </div>
                                        <div class="col">
                                            <cc-whatsapp :phone="seller.phone" />
                                        </div>
                                        <div class="col actions">
                                            <i @click.prevent="remove_seller(seller, client)"
                                                title="Remover"
                                                class="fa fa-trash" />
                                            <i @click="show_participations(seller)"
                                                title="Histórico de Cotações"
                                                class="fas fa-clipboard-list"></i>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="row sellers" v-for="client in sorted_clients" v-bind:key="client.id">
                                <div class="col col-12" v-if="
                                    client && !provider.sellers.some(seller => {
                                        return seller.clients.some(c => c.id == client.id)
                                    })" v-bind:key="client.id">
                                    <div class="col col-4 seller-client">
                                        {{ client.name }}
                                    </div>
                                    <div class="col col-3 name">
                                        <i class="fa fa-edit" @click="manage_sellers(client, provider)" title="Vendedores"></i>
                                    </div>
                                    <div class="col">
                                    </div>
                                    <div class="col">
                                    </div>
                                    <div class="col actions">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SellerParticipationModal
            :seller="current_seller"
            @close="is_seller_participation_modal_open=false"
            v-if="is_seller_participation_modal_open" />

        <SellersClientsManagementModal
            @close="is_seller_client_management_modal_open=false"
            v-if="is_seller_client_management_modal_open"
            :client="current_client"
            :provider="current_provider" />
    </div> -->
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/clientes" class="page-route-select-text">
        Clientes
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/clientes" class="page-route-select-text">
        Analítico
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Mapa Fornecedores x Clientes
      </div>
    </div>
    <div class="page-title">
      Mapa Fornecedores x Clientes
    </div>
    <form action="" @submit.prevent="list()">
      <div class="orders-page__top-actions-container">
        <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => {close()}" />
      </div>
      <div class="orders-page__actions-container">
        <cc-select-v2 tracker="id"
                      :text="'name'"
                      :searchable="true"
                      :placeholder="'Selecione um cliente'"
                      :options="clients"
                      v-model="filters.client" 
                      class="orders-page__content-inputs"/>
        <Button :iconName="'delete'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clear_filters" />
      </div>
    </form>
    <div>
      <cc-loader v-show="is_loading" class="default" />
      <no-data-available v-if="providers.length == 0 && !is_loading" :text="'Nenhum fornecedor encontrado'" />
      <div class="page-table-container" 
           v-for="provider in providers" 
           :key="'head-office-' + provider.head_office.id">
        <div class="page-table-header">
          <div class="page-table-header-text id-width">#{{ provider.id }}</div>
          <div class="page-table-header-text seller-width">
            <span class="material-icons-outlined seller-icon"
                  :title="provider.contact ? provider.contact.toUpperCase() : ''"
                  :style="{ visibility: !provider.head_office.is_head_office ? 'hidden' : 'visible' }">
              person
            </span>
          </div>
          <div class="page-table-header-text name-width">{{ provider.name }}</div>
          <div class="page-table-header-text cnpj-width">{{ provider.cnpj || "" | VMask('## ### ###/####-##') }}</div>
          <div class="page-table-header-text trading-width">{{ provider.trading_name }}</div>
          <div class="page-table-header-text state-width">{{ provider.head_office.state && provider.head_office.state.name }}</div>
          <div class="page-table-header-text city-width">{{ provider.head_office.city && provider.head_office.city.name }}</div>
        </div>
        <div v-for="seller in provider.sellers" :key="seller.id">
          <template v-for="client in seller.clients">
              <div class="page-table-row" 
                   :key="client.id" 
                   v-if="clients.some(c => c.id == client.id && (client.visible == undefined || client.visible))">
                  <div class="page-table-desc-column id-width">
                      {{ client.name }}
                  </div>
                  <div class="page-table-desc-column seller-width">
                      <i class="fas fa-user-tag seller-icon-2"
                         @click="manage_sellers(client, provider)"
                         :title="'Vendedor #' + seller.id "></i>
                      {{ seller.name }}
                  </div>
                  <div class="page-table-desc-column name-width">
                    <cc-percentage v-if="client.pivot" :value="client.pivot.participation" />
                  </div>
                  <div class="page-table-desc-column cnpj-width">
                    <cc-whatsapp v-if="seller.phone" :phone="seller.phone" />
                    <span v-else>-</span>
                  </div>
                  <div class="page-table-desc-column trading-width">
                      <span class="material-icons-outlined remove-icon"
                            @click.prevent="remove_seller(seller, client)"
                            title="Remover">
                        delete
                      </span>
                      <span @click="show_participations(seller)"
                            title="Histórico de Cotações"
                            class="material-icons-outlined assignment-icon">
                        assignment
                      </span>
                  </div>
                  <div class="page-table-desc-column state-width"></div>
                  <div class="page-table-desc-column city-width"></div>
              </div>
          </template>
        </div>
        <div v-for="client in sorted_clients" 
             :key="client.id">
          <div class="page-table-row" 
               v-if="client && !provider.sellers.some(seller => { return seller.clients.some(c => c.id == client.id)})" 
               :key="client.id">
            <div class="page-table-desc-column id-width">
              {{ client.name }}
            </div>
            <div class="page-table-desc-column seller-width">
              <span class="material-icons-outlined edit-icon"
                    @click="manage_sellers(client, provider)" 
                    title="Vendedores">
                edit
              </span>
            </div>
            <div class="page-table-desc-column name-width"></div>
            <div class="page-table-desc-column cnpj-width"></div>
            <div class="page-table-desc-column trading-width"></div>
            <div class="page-table-desc-column state-width"></div>
            <div class="page-table-desc-column city-width"></div>
          </div>
        </div>
      </div>
    </div>
    <SellerParticipationModal :seller="current_seller"
                              @close="is_seller_participation_modal_open=false"
                              v-if="is_seller_participation_modal_open" />
    <SellersClientsManagementModal @close="is_seller_client_management_modal_open=false"
                                   v-if="is_seller_client_management_modal_open"
                                   :client="current_client"
                                   :provider="current_provider" />
  </div>
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import ClientService from "@/services/v3/client.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import ReportService from "@/services/v3/providers/report.service";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import NewClientModal from "@/modals/client/new/new-client.modal";
import StoresModal from "@/modals/client/stores/management.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import UserManagementModal from "@/modals/client/users/management.modal";
import SellerService from '@/services/v3/providers/seller.service'
import SellerParticipationModal from "@/modals/providers/sellers/sellers-participation.modal";
import SellersClientsManagementModal from "@/modals/providers/sellers/sellers-clients-management.modal";
import Button from '@/components/ui/buttons/StandardButton.vue';

export default {
    mixins: [ loaderMixin ],
    props: [ "clients" ],
    components: {
        ccPaginacao: Paginacao,
        StoresModal,
        SellerParticipationModal,
        SellersClientsManagementModal,
        'user-management-modal': UserManagementModal,
        'cc-invitations-modal' : InvitationsModal,
        'cc-new-client-modal' : NewClientModal,
        Button,
    },
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            current_provider: null,
            filters: {
                client: null,
            },
            current_client: null,
            is_user_management_modal_open: false,
            is_seller_participation_modal_open: false,
            is_seller_client_management_modal_open: false,
            filterByProviders: [],
            items_by_page_value: 1000,
            service: new QuotationService(),
            svc: new ClientService(),
            user_svc: new UserService(),
            pr_svc: new ReportService(),
            seller_svc: new SellerService(),
            providers: [],
            breadcrumbs: [
                {
                    name: "Meus Vendedores",
                    route: 'sellers'
                }
            ],
            clientsList: [],
        }
    },
    methods: {
        close() {
          this.$emit('close')
        },
        setClientValue(value) {
          console.log(value)
          this.$forceUpdate()
        },
        clear_filters() {
            this.filters.client = null
            this.$forceUpdate()
        },
        manage_sellers(client, provider) {
            this.current_provider = provider
            this.current_client = client
            this.is_seller_client_management_modal_open = true
        },
        show_participations(seller) {
            this.current_seller = seller
            this.is_seller_participation_modal_open = true
        },
        remove_seller(seller, client) {
            this.confirm_action({
                message: `Confirma remoção do vendedor ${seller.name}?`,
                subtitle: `O vendedor não atenderá mais ao cliente ${client.name}`,
                callback: () => this.remove(seller, client)
            })
        },
        remove(seller, client) {
            client.is_attending = true
            this.seller_svc.toggle_client(client, seller.id).then(resp => {
                this.list(false)
                const notification = {
                    type: 'success',
                    message: (client.is_attending ? 'Removido' : 'Adicionado') + ' com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
            }).catch(() => {
                const notification = {
                    type: 'error',
                    message: 'Erro ao executar operação!'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        list(show_loader = true) {
            this.is_loading = show_loader;
            this.pr_svc.clients_report({ ids: this.clients.map(c => c.id) })
            .then(response => response.data)
            .then(data => {
                data.forEach(c => c.selected = false)
                this.providers = data;
                this.is_loading = false;
                this.providers.forEach(p => {
                    p.sellers.forEach(s => {
                        s.clients.sort((a, b) => {
                            if (a.name < b.name) return 1
                            if (b.name < a.name) return -1
                            return 0
                        })
                    })
                })
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    computed: {
		sorted_clients: function() {
            let res = this.clients.filter(s => !this.filters.client?.id || this.filters.client.id == s.id)
            res.forEach(client => {
              this.clientsList.push({
                text: client.name,
                value: client,
              })
            })
            res.sort((a, b) => {
                if (a.name > b.name) return 1
                if (b.name > a.name) return -1
                return 0
            })
            return res
        }
	},
    watch: {
        filters : {
            handler: function() {
                this.providers.forEach(p => {
                    p.sellers.forEach(s => {
                        s.clients.forEach(c => {
                            if(!this.filters.client?.id || this.filters.client.id == c.id) {
                                c.visible = true
                            } else {
                                c.visible = false
                            }
                        })
                    })
                })
                this.$forceUpdate()
            },
            deep: true
        }
    },
    created() {
        this.list();
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #ff7110;
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.orders-page__top-actions-container {
  display: flex;
  flex-direction: row-reverse;
  padding: 2em 0em;
}
.orders-page__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
}
.orders-page__content-inputs {
  width: 30vw !important;
}
.remove-icon {
  color: #CB595B;
  margin-right: 10px;
  cursor: pointer;
}
.assignment-icon {
  color: #20CE88;
  cursor: pointer;
}
.edit-icon {
  color: #505050;
  cursor: pointer;
}
.seller-icon {
  font-size: 24px;
  color: #fff;
}
.seller-icon-2 {
  font-size: 16px;
  color: #ff7110;
  cursor: pointer;
}
.page-table-container {
  margin: 2em 0em;
  box-shadow: 2px 2px 6px gray;
  border-radius: 8px;
}
.page-table-container:hover {
  box-shadow: 0px 0px 8px #ff7110;
}
.page-table-header {
  font-weight: 400;
  color: #fff;
  background-color: #ff7110;
  display: flex;
  border-radius: 8px 8px 0 0;
  padding: 16px;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #fff;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.6em 2em;
}
.id-width {
  width: 14%;
}
.seller-width {
  width: 22%;
}
.name-width {
  width: 16%;
}
.cnpj-width {
  width: 14%;
}
.trading-width {
  width: 14%;
}
.state-width {
  width: 10%;
}
.city-width {
  width: 8%;
}
.page-table-line-special {  
  background: #F7F7F7;
}

    /* .seller-icon {
        color: #fff;
        padding: 0 0.6rem;
        font-size: 1.5rem;
    }

    .filter-controls {
        button {
            margin-right: 10px;
        }
    }

    button.analyze {
        background: red;
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        border-radius: 40px;
        border: none;
        padding: 20px;
        color: white;
        &:hover {
            filter: drop-shadow(2px 4px 6px gray);
        }
        i {
            color: $ternary-color;
            font-size: 2rem;
            filter: hue-rotate(45deg);
        }
    }

    .provider {
        box-shadow: 2px 4px 6px gray;
        margin: 30px 15px;
        border-radius: 10px;
        .header {
            display: flex;
            border-radius: 10px 10px 0 0;
            padding: 10px;
            background-color: #ff7110;
            color: white;
        }
        .sellers {
            .col {
                align-items: center;
                display: flex;
                white-space: nowrap;
                font-size: 0.9rem;
                padding: 5px 15px;
                &.name {
                    display: flex;
                    align-items: center;
                    i {
                        color: $primary-color;
                        font-size: 1.1rem;
                        margin-right: 5px;
                        margin-bottom: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
        &:hover {
            box-shadow: 0px 0px 8px #ff7110;
        }
    }

    .clients {
        .client {
            display: flex;
            align-items: center;
            padding-left: 20px;
            justify-content: center;
            span {
                margin-right: 11px;
                color: #ab70f7 !important;
            }
        }
    }
 */
</style>
