import { render, staticRenderFns } from "./_providers_x_clients.component.vue?vue&type=template&id=51f27129&scoped=true&"
import script from "./_providers_x_clients.component.vue?vue&type=script&lang=js&"
export * from "./_providers_x_clients.component.vue?vue&type=script&lang=js&"
import style0 from "./_providers_x_clients.component.vue?vue&type=style&index=0&id=51f27129&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f27129",
  null
  
)

export default component.exports